import { IllusType } from '@wrisk/ui-components'

import bindError from './assets/illus/bind-error.svg'
import bindSuccess from './assets/illus/bind-success.svg'
import lineItem from './assets/illus/line-item.png'

const illus: IllusType = {
  lineItem: lineItem as string,
  bindError,
  bindSuccess,
  cancelSuccess: bindSuccess,
  paymentSuccess: bindSuccess,
  paymentError: bindError,
}

export default illus
